/**
 * Reviewer Plugin v.3
 * Created by Michele Ivani
 */

function rwpReCaptchaLoad() {
    var siteKey, id, rc;
    jQuery('.rwp-recaptcha').each(function(i){
        siteKey = jQuery(this).data('sitekey');
        id = jQuery(this).attr('id');
        rc = grecaptcha.render(id, {
          'sitekey' : siteKey
        });
        jQuery('#' + id).data('grcid', rc);
    });
}

(function( $ ) {
'use strict';
$(function() {

    // Trig Sliders
    if ( $('.rwp-scores-sliders').length > 0) {
        trigSliders();
    }

    // Knob
    if( $('.rwp-knob').length > 0) {
        trigKnob();   
    }

    // Knob
    if( $('.rwp-knob-table').length > 0) {
        trigTableKnob();   
    }

    var urid = getQueryParam('rwpurid');
    if( urid !== false ) {
        $(document).scrollTop( ($('#'+urid).offset().top - 10) );
    }

    // Submit User Review
    $('.rwp-submit-ur').click(function(e){
        e.preventDefault();

        var ref = $(this);

        var form        = $(this).parent().parent();
        var reviewId    = form.find('input[name="rwp-ur[review-id]"]').val();
        var postId      = form.find('input[name="rwp-ur[post-id]"]').val();
        var userId      = form.find('input[name="rwp-ur[user-id]"]').val();
        var mode        = form.find('input[name="rwp-ur[mode]"]').val();
        var template    = form.find('input[name="rwp-ur[template]"]').val();

        var userName = form.find('input[name="rwp-ur[name]"]').val();
        userName = ( typeof userName === "undefined" ) ? '' : userName;

        var title = form.find('input[name="rwp-ur[review_title]"]').val();
        title = ( typeof title === "undefined" ) ? '' : title;

        var comment = form.find('textarea[name="rwp-ur[comment]"]').val();
        comment = ( typeof comment === "undefined" ) ? '' : comment;

        var captchaId = '#rwp-recaptcha-' + postId + '-' + reviewId;
        var captchaEl = form.find(captchaId);
        var captcha     = ( captchaEl.length <= 0 ) ? false : grecaptcha.getResponse( captchaEl.data('grcid') );
        var recaptchaid = ( captchaEl.length <= 0 ) ? null :  captchaEl.data('grcid');
        var email = form.find('input[name="rwp-ur[email]"]').val();
        email = ( typeof email === "undefined" ) ? '' : email;

        var scores;

        switch( mode ) {

            case 'five_stars' : // 5 Stars rating

                var stars = form.find('input.rwp-rating:checked').val();
                scores = ( typeof stars === "undefined" ) ? 0 : stars;
                break;

            case 'full_five_stars': // 5 Stars full rating

                scores = [];
                var sliders = form.find('input.rwp-rating:checked');

                if( sliders.length != 0 ) {

                    sliders.each(function(i){
                        var obj = {};
                        obj.i = $(this).data('index');
                        obj.val = $(this).val();

                        scores.push( obj );
                    });
                }
                break;

            default: // Sliders full rating

                scores = [];
                var sliders = form.find('.rwp-slider');

                if( sliders.length != 0 ) {

                    sliders.each(function(i){
                        var obj = {};
                        obj.i = $(this).data('index');
                        obj.val = $(this).val();

                        scores.push( obj );
                    });
                }
                break;
        }

        var postData = {
            action: reviewerRatingObj.action,
            security: reviewerRatingObj.ajax_nonce,
            review_id: reviewId,
            post_id: postId,
            scores: scores,
            user_id: userId, 
            user_name: userName,
            user_email: email,
            title: title,
            comment: comment,
            method: mode,
            template: template,
            captcha: captcha
        }

        var loader = form.find('.rwp-loader');
        loader.fadeIn('fast');

        $.post(reviewerRatingObj.ajax_url, postData, function(response) {

            //console.log(response);

            var notification = form.find('.rwp-notification');
            notification.html('');

            switch( response.code ) {

                case 405:

                    loader.fadeOut('fast');
                    $.each(response.data, function(i,v) {
                        notification.append( v + '<br/>')
                    });
                    if(response.captcha) {
                        grecaptcha.reset(recaptchaid);
                    }
                    break;

                case 400:

                    loader.fadeOut('fast');
                    notification.html(response.data.msg);
                    break;

                case 401:

                    loader.fadeOut('fast');
                    notification.html(response.data.msg);
                    if(response.data.captcha) {
                        grecaptcha.reset(recaptchaid);
                    }
                    break;

                case 200:

                    notification.addClass('rwp-success')
                    notification.html(response.data.msg);
                    //form.slideUp(500);

                    var branch = form.find('input[name="rwp-ur[branch]"]').val();
                    branch = ( typeof branch === "undefined" ) ? false : true;

                    if( !branch ) {

                        $.ajax({
                            url: "",
                            cache: false,
                            context: document.body,
                            success: function(s,x){
                                loader.fadeOut('fast');

                                var newPage    = $(s);
                                var newReview  = newPage.find('#rwp-review-'+postId+'-'+reviewId + ' .rwp-review');

                                var container   = form.parent().parent().parent().parent();

                                container.find('.rwp-review').remove();
                                container.hide().append(newReview).fadeIn('fast');

                                if( container.find('.rwp-knob').length > 0) {
                                    trigKnob();   
                                }
                            }
                        });
                    
                    } else {

                        setTimeout(function() {
                               location.reload();
                        }, 1000);
                    }
                    break;

                case 201: 

                    loader.fadeOut('fast');
                    notification.addClass('rwp-success');
                    notification.html(response.data.msg);

                    var not = ref.parent();
                    not.find('input').remove();
                    var wrap = $('#rwp-ratings-form-'+ postId +'-'+reviewId);
                    wrap.find('.rwp-ratings-form').slideUp('fast');
                    wrap.append(not);

                default:
                    //console.log(response);
                    //alert('Error in rating process!');
                    break;
            } 
        
        }, 'json').fail(function( response ){
            loader.fadeOut('fast');
            alert('Error during rating process!');
        });

    });


    // Submit User Like
    $('.rwp-review-wrap').on('click', '.rwp-on .rwp-like span, .rwp-on .rwp-dislike span', function(e){
        e.preventDefault();

        var ratingId    = $(this).data('rating-id');
        var postId      = $(this).data('post-id');
        var method      = $(this).data('method');
        var userId      = $(this).data('user-id');

        var form        = $(this).parent().parent();

        var postData = {
            action: reviewerLikeObj.action,
            security: reviewerLikeObj.ajax_nonce,
            rating_id: ratingId,
            post_id: postId,
            user_id: userId,
            method: method
        }

        var loader  = form.find('.rwp-loader');
        loader.fadeIn('fast');
        var count   = $(this).parent().find('em');

        $.post(reviewerLikeObj.ajax_url, postData, function(response) {

            //console.log(response);

            loader.fadeOut('fast');

            switch( response.code ) {

                case 400:
                    alert(response.data.msg);
                    break;

                case 200:
                    
                    var countVal = ( method == 'like' ) ? response.data.like : response.data.dislike ;
                    count.html(countVal);

                    form.removeClass('rwp-on').addClass('rwp-off');
                    break;

                default:
                    //console.log(response);
                    alert('Error in like/dislike process!'); 
                    break;
            }

        }, 'json').fail(function( response ){
            loader.fadeOut('fast');
            alert('Error during like/dislike process!');
        });

    });

    // More Ratings
    $('.rwp-review-wrap').on('click', '.rwp-show-more', function(e){
        // var perPage = parseInt($(this).data('per-page'));
        // var ratings = $(this).parent().find('.rwp-users-reviews .rwp-user-review.rwp-hidden');

        // for (var i = 0; i < perPage; i++) {
        //     $(ratings[i]).removeClass('rwp-hidden');
        // }

        // ratings = $(this).parent().find('.rwp-users-reviews .rwp-user-review.rwp-hidden');

        // if(ratings.length <= 0) 
        //     $(this).remove()

        var pages = $(this).parent().find('.rwp-users-reviews-page.rwp-hidden');
            if ( ( pages.length - 1 ) <= 0 ) {
                $(this).fadeOut('fast', function(){
                    $(this).remove();
                });
            }
            pages.first().removeClass('rwp-hidden');
    });

    // Refresh captcha
    

    $('.rwp-refresh-captcha-btn').click(function(e){
        e.preventDefault();

        var reviewId    = $(this).data('review-id');
        var postId      = $(this).data('post-id');


        var postData = {
            action: rwpCaptchaObj.action,
            security: rwpCaptchaObj.ajax_nonce,
            review_id: reviewId,
            post_id: postId
        }

        var form = $(this).parent();


        $.post(rwpCaptchaObj.ajax_url, postData, function(response) {

            //console.log(response);

    
            switch( response.code ) {

                case 400:
                    alert(response.data.msg);
                    break;

                case 200:
                    
                    form.find('.rwp-captcha-image').attr('src', response.captcha);
                    form.find('.rwp-captcha-input').val('');
                    break;

                default:
                    //console.log(response);
                    alert('Error in like/dislike process!'); 
                    break;
            }

        }, 'json').fail(function( response ){
            loader.fadeOut('fast');
            alert('Error Refreshing Captcha');
        });

    });

    // Share Dialog
    $('.rwp-review-wrap').on('click', '.rwp-share.rwp-d', function(e){
        e.preventDefault();
        var viewportH = $( window ).height(),
            viewportW = $( window ).width(),
            url = $(this).attr('href');

        if ( viewportW <= 768 ) { // Mobile
            window.open(url);
        } else {
            var W = 670,
                H = 360,
                T = ( viewportH - H ) / 2,
                L = ( viewportW - W ) / 2;
            window.open(url, '_blank', 'height='+ H +', width='+ W +', top='+ T +', left='+ L +', menubar=no, resizable=yes, scrollbars=yes, status=no, titlebar=no, toolbar=no');
        }
    });

    // Share Prompt
    $('.rwp-review-wrap').on('click', '.rwp-share.rwp-sharing-link', function(e){
        e.preventDefault();
        var res = prompt( $(this).data('label'), $(this).attr('href') );
    });

    function getQueryParam(parm) {
       var query = window.location.search.substring(1);
       var vars = query.split("&");
       for (var i=0;i<vars.length;i++) {
               var pair = vars[i].split("=");
               if(pair[0] == parm){return pair[1];}
       }
       return(false);
    }

    

    function trigKnob() {
        
        $('.rwp-knob').knob({
            readOnly: true,
            thickness: .2, 
            width: 100,
            height: 100,
            bgColor: '#e5e5e5',
            inputColor: '#3b3c3e',
            fontWeight: 'bold' 
        });
    }

    function trigTableKnob() {
        
        $('.rwp-knob-table').knob({
            readOnly: true,
            thickness: .25, 
            width: 50,
            height: 50,
            bgColor: '#e5e5e5',
            inputColor: '#3b3c3e',
            fontWeight: 'bold',
            inline: false
            
        });
    }

    function trigSliders() {
        
        var decimals;
        
        $('.rwp-slider').each(function(i){

            decimals = getDecimals($(this).data('step'));
            
            $(this).noUiSlider({
                step: $(this).data('step'),
                start: $(this).data('val'),
                connect: "lower",
                range: {
                  'min': $(this).data('min'),
                  'max': $(this).data('max')
                },
                format: wNumb({
                    decimals: decimals,
                })
            });

            $(this).Link('lower').to($(this).parent().find('input'), null, wNumb({decimals: decimals }));
        });
    }

    function getDecimals(number) {
        var num = parseFloat(number);
        var match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
        if (!match)
            return 0;
        return Math.max(0, (match[1] ? match[1].length : 0) - (match[2] ? +match[2] : 0));
    }

});
})( jQuery );
